/* global require, up */

// call form.submit on [remove-button] click, after removing the element from the DOM
up.compiler("[remove-button]", function(element, data){
  const form = up.element.closest(element, "form")
  return up.on(element, "click", function(){
    let el_to_remove = up.element.closest(element, "[removable]")
    if(el_to_remove){
      up.element.remove(el_to_remove);
      up.submit(form)
    }
  })
})

/* global require, up, Cleave, FilePond, DataTable, tail, bb */

const {throttle} = require("../lib/utils")

//TODO: allow for selected data to be given separate from presentation
// do tail-select :selection true in JS when possible
function tail_select_init(element, data, selection){

  const form = element?.form;
  const autosubmit = up.util.isGiven(up.element.attr(form, "up-autosubmit")) || element?.hasAttribute("autosubmit");
  const delay = up.element.numberAttr(form, "up-delay") || up.element.numberAttr(element, "up-delay");

  const throttled_submit = throttle(up.submit, delay, {leading: false});

  const tail_select_data = element?.dataset?.tailSelect
  const tail_select_data_options = tail_select_data ? JSON.parse(tail_select_data) : {}

  //console.log("dataset", element?.dataset)

  const up_tail_select_options = data["tail-select"] ?? {};

  const dataset_options = element?.dataset ?? {}

  const placeholder = element.dataset.placehodler

  var tail_select_options = Object.assign(
    element.multiple ?
      {
        //sourceBind: true,
        //sourceHide: false,
        multiPinSelected: true,
        multiSelectGroup: true,
        multiContainer: true
      } : (selection.length === 0) ?
      {deselect: true} : {},
    up_tail_select_options,
    dataset_options,
    tail_select_data_options)

  console.log("tail-select-options", tail_select_options)
  let tails = tail.select(element, tail_select_options);

  if (selection.length === 0){
    tails.updateLabel(placeholder || "Choose an Option")
    const selected_options = tails.options.selected
    selected_options.forEach(opt => tails.options.unselect(opt)) // deselect any selected options
  }

  if (tail_select_options.invalid){
    element.parentElement.children[1].classList.add("is-invalid")
    element.parentElement.children[1].classList.add("custom-select")
  }

  function onchange(e){
    console.log("tail-select onchange", element, e);
    this.select.classList.remove("is-invalid")
    this.select.classList.remove("custom-select")
    if(this.value() === null) {
      this.e.selectedIndex = -1;
    }
    // FIXME: we shouldn"t have to do this, onchange should be triggered by the bound selectbox
    // currently onchange is called on first change, and no subsequent
    if (autosubmit){
      // can"t believe i have to fucking do this, tails or up-form randomly adds preventdefault to select change events
      throttled_submit(form);
    }
  }

  var unbind_onchange = tails.on("change", onchange)

  function oninvalid(e){
    this.parentElement.children[1].classList.add("is-invalid")
    this.parentElement.children[1].classList.add("custom-select")
    e.srcElement.parentElement.scrollIntoView()
  }

  var unbind_oninvalidate = up.on(tails.e, "invalid", oninvalid);

  // cleanup fn
  return function(){
    unbind_oninvalidate();
    unbind_onchange();
    tails.remove();
  }
}


// TODO: maybe we should try to do this on the form level, look for children tail-selects, and modify them
// one issue with this is that the form could have more children, then we are acting on them as well and things
// are a bit more complicated...
up.macro("select[tail-select][up-autosubmit]", function(element) {
  element.removeAttribute("up-autosubmit")
  element.setAttribute("autosubmit", "")
})

up.compiler("select[tail-select]", function(element, data) {

  // tail select"s API is so fucked up that setting selected values is almost impossible

  let selection = [];

  if (up.util.isString(data)){
    selection = [data]
  }
  else if (up.util.isArray(data)){
    selection = data
  }
  else if (data["selected-id"]){
    selection = [data["selected-id"]]
  }
  else {
    //console.error("missing selection for tail-select", element, data);
  }

  // legacy tail select doesn"t have <select> with <option>s
  if(element.querySelector(`option`)){
    selection.forEach((selected_key) => {
      const option = element.querySelector(`option[value="${selected_key}"]`)
      if(option){
        option.selected = true
      }
      else {
        console.error("option tag not found for key", selected_key, "on element", element)
      }
    })
  }

  tail_select_init(element, data, selection);
})

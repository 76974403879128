/* global require, up, FilePond */


// https://pqina.nl/filepond/docs/api/instance/events/#filepond-loaded-event
// document.addEventListener("FilePond:loaded", (e) => {
//   console.log("FilePond ready for use", e.detail);

//   // get create method reference
//   const { create } = e.detail;
// });

//TODO: add properties to input field https://pqina.nl/filepond/docs/api/instance/properties/
up.compiler("input[filepond]", function(element, data){
  // look for sibling object tag that has json data for filepond
  let files;
  try {
    files = JSON.parse(element.parentElement.querySelector("object").innerHTML)
  }
  catch {
    console.error("no sibling object tag with filepond json data as innerHTML found", element)
  }


  // Create a FilePond instance with files from the server
  if(FilePond.supported()){
    const pond = FilePond.create(element)
    const options = {
      storeAsFile: false,
      server: {
        url: element?.dataset?.server ?? "./"
        // revert, at least, doesn"t send HTTP when uncommented....
        // load: (src, load) => {
        //   fetch(src)
        //     .then(res => res.blob())
        //     .then(load);
        // },
        // revert: (src, load) => {
        //   console.log("revert", src);
        //   load();
        // },
        // remove: (src, load) => {
        //   console.log("remove", src);
        //   load();
        // }
      }

      //https://pqina.nl/filepond/docs/api/instance/properties/#files
      //files: [],
      //storeAsFile: true,
      //allowMultiple: true
    }

    if(files?.length){
      options.files = files
    }

    //console.log("filepond options", options)
    pond.setOptions(options);

    return function(){
      FilePond.create(element)
    }
  }
})

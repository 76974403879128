/* global require, up, tippy */

tippy.setDefaultProps({
  delay:     400,
  placement: "top",
  animation: "scale-extreme",
  inertia:   true,
  theme:     "light"
});

up.macro("[title]", {batch: true}, function(elements){
  elements.forEach(element => {
    const title_text = up.element.attr(element, "title")
    element.removeAttribute("title")
    up.element.setAttrs(element, {"data-tippy-content": title_text})
  })
})

up.macro("[data-tippy-content]", {batch: true}, function(elements){
  elements.forEach(function(element){
    element.removeAttribute("title")
  })
})

up.macro("[data-tippy-trigger=\"click\"]", {batch: true}, function(elements){
  elements.forEach(function(element){
    up.element.setAttrs(element, {"data-tippy-delay" : 0})
  })
})

up.compiler("[data-tippy-content]", {batch: true}, function(elements){
  elements.forEach(function(element){
    tippy(element);
  })
})
